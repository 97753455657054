@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
}

div#root {
  max-width: 1920px;
  margin: 0 auto;
}

@layer utilities {
  body::-webkit-scrollbar {
    width: 5px;
  }

  body::-webkit-scrollbar-track {
    background: #fff;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #e2e3e4;
    border-radius: 2px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}